@use '@angular/material' as mat;
@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';
@import "animate.css";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app. 

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,700;1,900&display=swap');   

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

.text-shadow {
  text-shadow: 0 2px 4px rgba(0,0,0,0.10);
}

.text-shadow-md {
  text-shadow: 0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08);
}

.text-shadow-lg {
  text-shadow: 0 15px 30px rgba(0,0,0,0.11), 0 5px 15px rgba(0,0,0,0.08);
}

.text-shadow-none {
  text-shadow: none;
} 
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$osi-primary: mat.define-palette(mat.$blue-palette);
//$osi-accent: mat-palette($mat-green, A200, A100, A400);
$osi-accent: mat.define-palette(mat.$light-blue-palette);

// The warn palette is optional (defaults to red).
$osi-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$osi-theme: mat.define-light-theme((
  color: (
    primary: $osi-primary,
    accent: $osi-accent,
    warn: $osi-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($osi-theme);

/* You can add global styles to this file, and also import other style files */

html, body { 
  height: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
body { margin: 0; font-family: 'Roboto', sans-serif; }  
.hide{
  display:none !important;
}
.mat-tab-body-content
{
    overflow-y:hidden !important;
}
.mat-tab-label-active.mat-tab-label .mat-tab-label-content{
    color:#0079be !important
}
.mat-tab-label-active{
    opacity:1 !important;
}
 
.mat-progress-bar-fill::after {
  background-color: #2196f3;
}

.mat-progress-bar-buffer {
  background-color: #dadada;
}

.mat-button-wrapper svg{
  margin-left:6px;
}

.bg-info {
  background-color: #edf9ff;
}

mat-step-header{
  background:none !important;
}

.mat-horizontal-content-container{
  padding:0px !important;
}
 
 .mat-form-field-wrapper{
   padding-bottom:0px;
 } 
 .mat-form-field-invalid .mat-form-field-wrapper{
  padding-bottom: 1.34375em;
 }
 
mat-bottom-sheet-container{
  padding:16px !important;
  overflow-x:hidden !important;
  width:calc(75vw + 32px) !important;
} 
 
.flex-collapse{
  display:flex;
  flex-direction: column;
  .header{
      font-weight:bold !important;
      color:#616161 !important;
      text-transform: uppercase !important;
  }
  .header:hover{
      background-color:#fff !important;
  }
  .row:last-child{
      border-bottom:none;
  }
  .row:hover{ 
    background-color:  rgba(243, 244, 246);
  }
  .row{
      display:flex;
      flex-direction:row;
      flex-wrap: wrap;
      height:40px;
      line-height:40px;
      overflow:hidden; 
      border-bottom:solid 1px rgba(229, 231, 235);
      div{
          flex:1;  
          display:flex;
          width:16%; 
          min-width:50px;
          padding: 0px 8px;
          span{
              text-overflow: ellipsis;  
              white-space: nowrap;
              overflow:hidden; 
          }
      }
  }
} 

.ellipsis{
  text-overflow: ellipsis;  
  white-space: nowrap;
  overflow:hidden;  
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper{
  max-width:100% !important;
} 

.mat-progress-bar-lg.mat-progress-bar{
  border-radius:4px !important;
  height:32px !important;
}

.mat-form-field-disabled{
  .mat-form-field-outline{
    background-color:#f1f1f1 !important;
    overflow:hidden !important;
    border-radius:2px !important; 
  }
}
.flex-0{
  flex:0 !important;
}
.mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after{
  color: rgba(0, 0, 0, .8);
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label{
  color: rgba(0, 0, 0, .8);
}
.mat-chip.mat-standard-chip.mat-chip-disabled{
  opacity:.8;
}
.mat-form-field-disabled{
  mat-label{
    background-color:#ffffff;
    padding:2px;
    border-radius:4px;
  }
  .mat-empty.mat-form-field-empty{
    mat-label{
      background-color:#f1f1f1 !important;
    }
  }
}
.mat-force-disabled{
  mat-label{
    background-color:#ffffff;
    padding:2px;
    border-radius:4px;
  }
  .mat-empty.mat-form-field-empty{
    mat-label{
      background-color:#f1f1f1 !important;
    }
  } 
  .mat-form-field-outline{
    background-color:#f1f1f1 !important;
    overflow:hidden !important;
    border-radius:2px !important; 
  }
}

.card-large-header{
  font-family: 'Roboto', sans-serif;
  font-weight:900;
}

[mattooltip], [mattooltipposition] {  touch-action: auto !important; }

@media (max-width: 640px) {
  .mat-vertical-content-container{
    margin-left:20px !important;
  }
  .mat-vertical-content{
    padding:0 24px 24px 24px !important
  }
  .mat-vertical-stepper-header
  {
    padding-left:8px;
    margin-left:0px;
  }
  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header
  {
    padding:8px;
  }
  .mat-stepper-vertical-line::before{
    top:-8px;
    bottom:-8px;
  } 
}

.mat-step-header, mat-icon{
  user-select: none;
}

.cdk-global-scrollblock
{
  overflow: auto !important;
}

.list{
  list-style:circle !important;
  margin:16px !important;
  li{
    padding:8px;
  }
}
.mat-icon-button.disabled mat-icon{
  color:#ccc !important;
}